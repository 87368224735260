export enum StoreName {
  CANCELLATIONS = 'CANCELLATIONS',
  CIRCULAR_LETTERS = 'CIRCULAR_LETTERS',
  COUNTRIES = 'COUNTRIES',
  DISTRIBUTIONS = 'DISTRIBUTIONS',
  MEMBERSHIP = 'MEMBERSHIP',
  PHONE_COUNTRY_CODES = 'PHONE_COUNTRY_CODES',
  POSTAL_CODES = 'POSTAL_CODES',
  TARIFF_CONTENT = 'TARIFF_CONTENT',
}
